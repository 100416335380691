import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { GoogleMapService } from 'src/app/services/google-map.service';
import { LocationService } from 'src/app/services/location.service';
import { ProductService } from 'src/app/services/product.service';
import { Location } from '@angular/common';
import { LoaderService } from "src/app/services/loader.service";
import { ConfigServiceService } from 'src/app/services/config-service.service';

declare var google:any;

@Component({
  selector: 'calsoft-location-map-all',
  templateUrl: './location-map-all.component.html',
  styleUrls: ['./location-map-all.component.scss']
})
export class LocationMapAllComponent implements OnInit {

  map: any;
  latitude: any
  longitude: any;
  marker:any;
  private googleMapsScriptLoaded: boolean = false;


  mapListener: any;
  mapChange: Subscription;
  data: any;
  addressDetail: any;

  locationSelected: boolean;
  location: any = {};
  isLocationFetched: boolean;
  routelocation:boolean;
  separatedAddress: any;
  googleApiKey:any;

constructor(
  public _location: Location,
  public productService: ProductService,
  private commonService: CommonService,
  public formBuilder: FormBuilder,
  private maps: GoogleMapService,
  private locationService: LocationService,
  public router: Router,
  private dialog: MatDialog,
  private renderer: Renderer2,
  private loaderService: LoaderService,
  private configService: ConfigServiceService,
) {

}

ngOnInit() {
  this.getConfigData();

}


getConfigData() {
  this.configService.getConfigData()
    .subscribe(
      data => {
        this.googleApiKey = data['googleApi'];
        this.loadGoogleMapsScript();
      })
    }

loadGoogleMapsScript() {
  if (!this.googleMapsScriptLoaded) {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+this.googleApiKey+'&libraries=places';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.googleMapsScriptLoaded = true;
      this.displayMap();

    };
    document.body.appendChild(script);
  } else {
    this.displayMap();
  }
}


displayMap() {

  let options: any = {
    maximumAge: 0,
    timeout: 4000,
    enableHeightAccuracy: true
  };
  
  let style = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        { saturation: -100 }
      ]
    }
  ];

    // Array of coordinates
  
    let coordinates = [
      { lat: 12.956634490938901, lng: 80.25871497651819, icon:'assets/icons/location-pin.png', address:'12, test1' }, 
      { lat: 12.950110061062997, lng: 80.23957473336647, icon:'https://img.icons8.com/search', address:'14, test2' }, 
      { lat: 12.957261830958831, lng: 80.23395282338244, icon:'assets/icons/icons-location.png', address:'2, demotest1' }, 
      { lat: 12.96720421409836, lng: 80.24781484928408, icon:'https://img.icons8.com/search', address:'25, demotest2' }, 

    ];
    
      navigator.geolocation.getCurrentPosition((resp) => {

        this.latitude = resp.coords.latitude;
        this.longitude = resp.coords.longitude;
      
        let latLng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
        let mapOptions = {
          center: latLng,
          zoom: 13,
          scaleControl: false,
          streetViewControl: false,
          zoomControl: false,
          overviewMapControl: false,
          mapTypeControl: false,
          mapTypeId: 'SwiggyClone', // Use the identifier as a string, not a constructor
          mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'SwiggyClone']
      }
      };

        this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
          // Create a custom map type
          var mapType = new google.maps.StyledMapType(style, { name: 'Grayscale' });
          this.map.mapTypes.set('SwiggyClone', mapType);
          // Set the map to use the custom map type
          this.map.setMapTypeId('SwiggyClone');

      coordinates.forEach(coord => {
        this.add_Marker(coord.lat, coord.lng, coord.icon, coord.address);
      });
        //this.addMarker(resp.coords.latitude, resp.coords.longitude);
  
    },
    (error) => {
      console.log('Error gettinglal location' + JSON.stringify(error));
    },
    options
  );

}

add_Marker(latitude, longitude, icons, address) {
  const icon = {
    url: icons,
    scaledSize: new google.maps.Size(40, 40),
  };

  let marker = new google.maps.Marker({
    map: this.map,
    position: { lat: latitude, lng: longitude },
    animation: google.maps.Animation.DROP,
    icon: icon,
  
  });

  // Create a new InfoWindow
  var infoWindow = new google.maps.InfoWindow({
    content: address // You can set any content you want to display in the info window
});

// Add click event listener to the marker
marker.addListener('click', () => {
    // Open the InfoWindow when the marker is clicked
    infoWindow.open(this.map, marker);
});


}


}
